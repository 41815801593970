@tailwind base;
@tailwind components;
@tailwind utilities;


html,body{
  /*overflow-x: hidden;*/
  width: 100%;
  height: 100%;
}

.Home{
  cursor:url(/public/cursor.svg), auto;
}

/*.cursor{*/
/*  background-image: url("../public/cursor.svg");*/
/*  height: 5rem;*/
/*  width: 5rem;*/
/*  bg-repeat: no-repeat;*/
/*    background-size: contain;*/
/*  position: fixed;*/
/*  top: 0;*/
/*  left: 0;*/
/*}*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
